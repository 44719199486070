
function imgUrl(bu,iu, cloudModify = undefined) {
        if (typeof(iu) !== 'string') {
            return
        }
	var ex = '';
	if (cloudModify && iu.indexOf('?')<0) {
		if (cloudModify.startsWith('@t:')) {
			ex = '?imageMogr2/thumbnail/'+cloudModify.substr(3)
		} 
	}
    // if (ex === '') {
    //     console.trace('url:', iu)
    // }
    if (iu && (iu.startsWith("//") || iu.startsWith("http://") || iu.startsWith("https://"))) {
        return iu + ex
    }
    return bu + iu + ex
}

export {imgUrl}
