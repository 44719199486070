<template>
    <div class="footer" :class="{m_footer:$store.state.screenType}">
        <div class="footer_logo" v-if="$store.state.screenType">
            <div class="img">
                <img src="../../assets/footer/footer_logo.png" alt="" v-if="!$store.state.language">
                <img src="../../assets/logo_01.png" alt="" v-if="$store.state.language">
            </div>
        </div>
        <div class="footer_header">
            <span v-show="$store.state.screenType">{{ $t('Footer.follow').toUpperCase() }}</span>
            <a :href="value.clickUrl" v-for="(value,index) in list">
                <img :src="imgUrl(baseUrl,value.iconUrl)" alt="">
            </a>

        </div>
        <div class="footer_line" v-if="!$store.state.screenType"></div>
        <!---->
        <div class="footer_main">
            <div class="footer_main_logo" v-if="!$store.state.screenType">
                <img src="../../assets/footer/footer_logo.png" alt="" v-if="!$store.state.language">
                <img src="../../assets/logo_01.png" alt="" v-if="$store.state.language">
            </div>
            <div class="footer_main_text" :class="{en_text:$store.state.language}">
                <!--加入我们-->
                <div class="text_join">
                    <h3>{{ $t('Footer.phone').toUpperCase() }}</h3>
                    <div class="arrows" v-if="$store.state.screenType" @click="unfold(1)"></div>
                    <div class="join_href" :class="{active:index===1}">
                        <span v-for="(value,index) in $t('Footer.href')" @click="jumpHr(index)">
                            {{ value.name }}
                        </span>
                    </div>
                </div>
                <!--联系我们-->
                <div class="text_phone">
                    <h3>{{ $t('Footer.join').toUpperCase() }}</h3>
                    <div class="arrows" v-if="$store.state.screenType" @click="unfold(2)"></div>
                    <div class="join_href" :class="{active:index===2}">
                        <span v-for="(value,index) in $t('Footer.joinList')" @click="jumpPage(index)">
                            {{ value.name }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_line"></div>
        <!---->
        <div class="footer_footer">
            <div class="footer_footer_text">
<!--
                <p>All Right Reserved 英雄体育管理有限公司版权所有</p>
-->

<!--
                <p> Powered by www.vspo.cn and www.vspo.global and www.vsgo.com</p>
                <p>www.vspo.cn（<a href="https://beian.miit.gov.cn">陕ICP备2020013907号-2</a>）</p>
                <p>www.vspo.global（<a href="https://beian.miit.gov.cn">陕ICP备2020013907号-4</a>）</p>
                <p>www.vsgo.com（<a href="https://beian.miit.gov.cn">陕ICP备2020013907号-6</a>）</p>
-->

<!--
                <p><a href="https://beian.miit.gov.cn">陕ICP备2020013907号</a></p>
-->
				<p style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">

<!--
                         <div style="width:300px;margin:0 auto; padding:20px 0;">
-->

	 <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61011302001744">
        <img src="@/assets/footer/beian.jpg" style="float:left;"/>
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">陕公网安备 61011302001744号</p >
	</a >
    &nbsp;
    &nbsp;

	<a href="https://beian.miit.gov.cn" style="display:inline-block;text-decoration:none;height:40px;line-height:20px;" >
       <span style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">陕ICP备2020013907号</span>
	</a>

    &nbsp;
    &nbsp;
	<a href="#" onclick="return false" style="display:inline-block;text-decoration:none;height:40px;line-height:20px;" >
       <span style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">英雄体育管理有限公司</span>
	</a>
<!--
                         </div>
-->
				</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { imgUrl } from "@/utils/urlutils"

export default {
    name: "footers",
    data () {
        return {
            index: -1,
            flag: true,
            list: []//底部四个小图标
        }
    },
    created () {
        this.getList()
    },
    methods: {
        imgUrl,
        unfold (index) {
            if (this.flag) {
                this.index = index
                this.flag = false
            } else {
                this.index = 0
                this.flag = true
            }
        },
        //加入我们
        jumpHr (index) {
            console.log(index)
            switch (index * 1) {
                case 0:
                    location.href = 'http://hr.vspo.cn/society'
                    break;
                case 1:
                    location.href = 'http://hr.vspo.cn/school'
                    break;
                case 2:
                    location.href = 'http://hr.vspo.cn/'
                    break;
                default:
                    break;
            }
        },
        //联系我们
        // 点击跳转链接
        jumpPage (index) {
            console.log(index)
            if (index === 1) {
                this.$router.push({
                    path: '/introduce'
                })
                this.$store.commit('changeIndex', 4)
            } else {
                this.$router.push({
                    path: '/media'
                })
                this.$store.commit('changeIndex', 3)
            }
        },
        //获取图标的超链接
        getList () {
            this.$http.getFooter().then(res => {
                console.log(res)
                this.list = res.data.data
            })
        }
    },
    computed: {
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">

/*-- -------footer---------- --*/
.footer {
    width: 100%;
    max-width: 1920px;
    height: 70.375rem;
    background-color: #000;
    padding: 5.5rem 10rem;
    box-sizing: border-box;
    margin: 0 auto;
    color: #fff;

    &_logo {
        width: 100%;
        height: 12.5rem;
        position: relative;

        .img {
            width: 15.375rem;
            height: 5rem;
            position: absolute;
            top: 0;
            left: calc(50% - 7.6875rem);

            img {
                width: 100%;
            }
        }
    }

    &_header {
        width: 100%;
        height: 6.25rem;
        display: flex;
        justify-content: left;
        align-items: center;

        span {
            font-size: 1.875rem;
            line-height: 4.75rem;
            font-family: 'AlibabaPuHuiTiB';
        }

        a {
            display: inline-block;
            margin-left: 3.3125rem;
            width: 2.5625rem;
            height: 2.5625rem;

            img {
                width: 100%;
            }
        }
    }

    &_line {
        width: 100%;
        height: 0.125rem;
        background-color: #808080;
    }

    &_main {
        height: 38.0625rem;
        display: flex;
        justify-content: left;
        flex-wrap: wrap;

        &_logo {
            width: 50%;

            img {
                display: block;
                margin: 15rem 0 15rem 3.75rem;
                width: 50%;
            }
        }

        &_text {
            width: 50%;
            padding-top: 5.75rem;
            box-sizing: border-box;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            color: #fff;

            .arrows {
                width: 1.5625rem;
                height: 0.75rem;
                background-image: url('../../assets/m_01.png');
                background-size: 100% auto;
                position: absolute;
                right: 0;
                top: 1rem;
            }

            h3 {
                font-size: 2.25rem;
                padding-right: 3rem;
                padding-bottom: 3.4375rem;
            }

            .text_join {
                position: relative;

                .join_href {
                    cursor: pointer;
                }
            }

            .text_phone {
                position: relative;

                span {
                    cursor: pointer;
                }
            }

            span, a {
                display: block;
                font-size: 1.5rem;
                color: #fff;
                padding-bottom: 3.25rem;
            }
        }


    }

    &_footer {
        &_text {
            height: 7.8125rem;
            margin: 3.125rem 3.5625rem;
            font-size: 1rem;
            line-height: 2.6rem;
            color: #fff;
        }
    }
}

.en_text {
    a {
        font-family: 'Gotham-ExtraLight';
    }
}

@import "m-footers";
</style>
